import React from 'react';

const IconLogo = () => (
  <svg
    width="974"
    height="967"
    viewBox="0 0 974 967"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M172.61 172.61L0 344.92L69.29 414.16L138.53 483.45L69.39 552.59C31.36 590.62 0.25 621.83 0.25 621.97C0.25 622.11 77.96 699.78 172.91 794.53L345.57 966.89L416.12 896.05L486.67 825.25L557.22 896.09L627.82 966.93L752.13 842.82C820.49 774.56 898.25 696.94 924.88 670.31L973.33 621.96L904.04 552.67L834.8 483.43L903.94 414.29C941.97 376.26 973.08 345.05 973.08 344.91C973.08 344.77 895.37 267.1 800.42 172.35L627.76 -2.20135e-06L557.21 70.84L486.66 141.64L416.21 70.89C377.4 31.99 345.61 0.190037 345.46 0.190037C345.31 0.240037 267.55 77.81 172.61 172.61ZM417.62 224.02L486.66 293.06L555.8 223.92L624.94 154.78L655.47 185.36L686.05 215.89L624.46 277.48L562.87 339.07L600.36 376.56L637.85 414.05L699.44 352.51L761.03 290.92L789.76 319.35L818.49 347.78L750.71 415.61L682.88 483.44L750.71 551.27L818.49 619.1L789.76 647.54L761.03 675.98L699.44 614.39L637.85 552.85L600.36 590.34L562.87 627.83L624.46 689.42L686.05 751.01L655.47 781.54L624.94 812.12L555.8 742.98L486.66 673.84L417.52 742.98L348.38 812.12L317.85 781.54L287.27 751.01L348.86 689.42L410.45 627.83L372.96 590.34L335.47 552.85L273.88 614.39L212.29 675.98L183.56 647.54L154.83 619.1L222.61 551.27L290.44 483.44L222.61 415.61L154.83 347.78L183.56 319.35L212.29 290.92L273.88 352.51L335.47 414.05L372.96 376.56L410.45 339.07L348.86 277.48L287.27 215.89L317.7 185.46C334.45 168.71 348.23 155.03 348.37 155.03C348.51 155.03 379.69 186.09 417.62 224.02Z"
    />
  </svg>
);

export default IconLogo;
