import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 650px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  p {
    color: var(--light-blue);
  }

  .centered {
    justify-content: center;
    margin-bottom: 20px;
    font-size: var(--fz-xxl);
    font-weight: 400;
    color: var(--pink-light);
    top: 0;

    &:before {
      font-size: var(--fz-sm);
      margin-top: 0;
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 8vw, 60px);
    line-height: 1;
    color: var(--slate);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading centered">What’s Next?</h2>
      <h2 className="title">Lets Get In Touch!</h2>
      <p>
        If you like my work or think my experience could be a good fit, feel free to reach out! I’m
        always up for ambitious, exciting projects. And if you just have a question or something
        else in mind, don’t hesitate to ask!
      </p>
      <a className="email-link" href={`mailto:${email}`}>
        <span className="button-text">Say Hello</span>
        <div className="button-mask"></div>
      </a>
    </StyledContactSection>
  );
};

export default Contact;
