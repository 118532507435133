import React, { Suspense, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Canvas } from '@react-three/fiber';
import { scroller } from 'react-scroll';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion, useWindowDimensions } from '@hooks';
import { Link } from 'gatsby';

import styled from 'styled-components';

import Model from './geo';
import Effects from './effects';

import PropTypes from 'prop-types';

const StyledEntrySection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;
  padding: 0;
  position: relative;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 25px 4px;
    color: var(--pink-light);
    font-size: clamp(var(--fz-md), 15vw, var(--fz-xl));
    font-weight: 500;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  .big-heading {
    color: var(--slate);
  }

  h3 {
    margin-top: 10px;
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const StyledInfoDiv = styled.div`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  width: 100%;
  color: var(--light-blue);

  @media screen and (max-width: 1366px) {
    width: 100%;
  }
`;

const StyledModelDiv = styled.div`
  min-height: 100vh;
  padding: 0;
  width: 50%;
  min-width: 650px;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (max-width: 1366px) {
    display: none;
  }

  .canvas canvas {
    height: 100vh;
  }
`;

const Content = ({ style, className, position }) => (
  <Canvas
    className={className}
    style={style}
    shadows
    raycaster={{ enabled: false }}
    dpr={[1, 2]}
    camera={{ position: [0, 0, 4.5], far: 1000 }}
    gl={{
      powerPreference: 'high-performance',
      alpha: true,
      antialias: false,
      stencil: false,
      depth: false,
    }}
    onCreated={({ gl }) => gl.setClearColor(0x020c1b, 0)}>
    <pointLight position={[-10, -10, -10]} intensity={1} />
    <ambientLight intensity={0.4} />
    <spotLight
      castShadow
      angle={0.3}
      penumbra={1}
      position={[0, 10, 20]}
      intensity={5}
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
    />
    <Suspense fallback={null}>
      <Model position={[0.45, 0, 0]} mouse={position} />
    </Suspense>
    <Effects />
  </Canvas>
);

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [position, setPosition] = useState([]);

  const { height, width } = useWindowDimensions();

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const scrollTo = (e, container) => {
    e.preventDefault();
    scroller.scrollTo(container, {
      smooth: true,
      offset: -30,
    });
  };

  const blockOne = <h1>Hello, my name is</h1>;
  const blockTwo = <h2 className="big-heading">Eduard Yudinkov</h2>;
  const blockThree = <h3 className="big-heading">Software Developer</h3>;
  const blockFour = (
    <>
      <p>
        I'm a senior software developer focused on turning ideas into functional and delightful user
        experiences. I have strong experience working with complex business problems and creating
        unique solutions.
      </p>
    </>
  );
  const blockFive = (
    <Link className="email-link" to="#" onClick={e => scrollTo(e, 'projects')}>
      <span className="button-text">Discover my projects</span>
      <div className="button-mask"></div>
    </Link>
  );
  const items = [blockOne, blockTwo, blockThree, blockFour, blockFive];

  return (
    <StyledEntrySection
      id="home"
      onPointerMove={e => setPosition([(e.clientX / width) * 2 - 1, (e.clientY / height) * 2 - 1])}>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <>
          <StyledInfoDiv>
            <TransitionGroup component={null}>
              {isMounted &&
                items.map((item, i) => (
                  <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                    <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
                  </CSSTransition>
                ))}
            </TransitionGroup>
          </StyledInfoDiv>
          <StyledModelDiv>
            <TransitionGroup component={null}>
              {isMounted && (
                <CSSTransition classNames="fade" timeout={loaderDelay}>
                  <Content
                    style={{ transitionDelay: `600ms` }}
                    className="canvas"
                    position={position}
                  />
                </CSSTransition>
              )}
            </TransitionGroup>
          </StyledModelDiv>
        </>
      )}
    </StyledEntrySection>
  );
};

Content.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.array,
  width: PropTypes.number,
};

export default Hero;
