import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';

import styled from 'styled-components';
import sr from '@utils/sr';

const StyledAboutContent = styled.div`
  .intro-text {
    color: var(--slate);
    font-size: var(--fz-exl);
    line-height: 36px;
    display: block;
    font-weight: bold;
  }

  .about-intro {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 40px;

    ul.skills-list {
      display: grid;
      grid-template-columns: repeat(2, minmax(140px, 200px));
      grid-gap: 0 10px;
      padding: 0;
      margin: 20px 0 0 0;
      overflow: hidden;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 20px;
        font-size: var(--fz-md);

        &:before {
          content: '▹';
          position: absolute;
          left: 0;
          color: var(--pink-light);
          font-size: var(--fz-sm);
        }
      }
    }

    p {
      color: var(--light-blue);
    }

    .about-text {
      padding-top: 4%;
      box-sizing: border-box;
      width: 40%;
    }

    .about-picture {
      width: 50%;
      z-index: 20;

      img {
        width: 100%;
      }
    }
  }

  .about-services {
    display: flex;
    margin-top: -6%;

    .content-services {
      background-color: #eea1be;
      box-sizing: border-box;
      padding: 100px;
      width: 65%;

      p {
        color: #52436c;
      }

      h2 {
        color: var(--light-navy);
        font-size: var(--fz-heading);
      }

      .text {
        margin: 40px 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        list-style: none;

        li {
          width: 46%;
          color: #18033c;
          font-weight: bold;
          font-size: var(--fz-exl);

          p {
            margin-top: 20px;
            font-weight: normal;
            font-size: var(--fz-xl);
          }

          &:nth-child(3) {
            margin-top: 30px;
          }

          &:nth-child(4) {
            margin-top: 30px;
          }
        }
      }
    }

    .resume {
      margin: 220px 0 0 60px;
      width: 23%;

      h3 {
        display: flex;
        align-items: center;
        color: var(--slate);
        margin: 32px 0;
        font-size: var(--fz-exl);
      }

      p {
        margin-bottom: 30px;
        color: var(--light-blue);
      }

      a {
        letter-spacing: 1px;
        border: 2px solid #574cf2;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        transition: background-color 200ms, border 200ms;

        &:hover {
          background-color: #18033c;
          color: #f3f2f4;
          border: 1px solid #18033c;
        }
      }
    }
  }

  @media (min-width: 834px) and (max-width: 1466px) {
    .about-services {
      margin-top: 60px;
      flex-direction: column;

      .content-services {
        width: 100%;
        padding: 60px 80px;
      }

      .resume {
        margin: 8px 0 0 0px;
        width: 100%;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 833px) {
    .intro-text {
      font-size: 20px;
      line-height: 32px;
    }

    .about-intro {
      flex-direction: column-reverse;
      padding-left: 0px;

      .about-text {
        width: 100%;
        padding-top: 0px;
      }

      .about-picture {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    .about-services {
      margin-top: 60px;
      flex-direction: column;

      .content-services {
        width: 100%;
        padding: 40px 40px;

        .text {
          margin: 25px 0;
        }

        ul {
          justify-content: center;

          li {
            &:not(:first-child) {
              margin-top: 15px;
            }

            width: 100%;

            p {
              margin-top: 15px;
            }
          }
        }
      }

      .resume {
        width: 100%;
        margin: 8px 0 0 0px;
      }
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig(0, 0.1));
  }, []);

  const skills = [
    'JavaScript (ES7)',
    'Angular (2+)',
    'React',
    'NestJS',
    'GraphQL',
    'WebSockets',
    'Flutter',
    'PostgreSQL',
  ];

  return (
    <section id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <StyledAboutContent className="content-about">
        <div className="about-intro">
          <div className="about-text">
            <p>
              <span className="intro-text">
                I’m Eduard Yudinkov, a Senior Software Developer and Team Lead with 8+ years of
                experience across B2B, B2C, and G2C projects
              </span>
              <br />
              I've worked with all kinds of tech companies, from fresh startups to well-established
              organizations. I love taking complex problems and turning them into smart, practical
              solutions that make a real impact.
            </p>
            <p>
              Over the years, I’ve worked in <a href="https://www.payvyne.com/">a start-up</a>,{' '}
              <a href="https://www.aeroflot.ru/ru-en">for an airline</a>,{' '}
              <a href="https://www.gosuslugi.ru">with public service agencies</a>, and in{' '}
              <a href="https://robo-voice.ru/">an IT-company</a>.
            </p>
            <p>Some of the technologies I work with:</p>
            <ul className="skills-list">
              {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
          <div className="about-picture">
            <StaticImage
              className="img"
              src="../../images/me.png"
              aspectRatio={1}
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              placeholder="blurred"
              alt="Me"
            />
          </div>
        </div>
        <div className="about-services">
          <div className="content-services">
            <h2>My Primary Skills</h2>
            <p className="text">
              I’m a developer who loves turning complex problems into simple, elegant solutions.
              Over the years, I’ve learned that picking the right tools for the job can make all the
              difference. Here’s how I put my favorite technologies to work:
            </p>
            <ul>
              <li>
                NodeJS / Golang
                <p>
                  I rely on these for building reliable, high-performance backends that can scale
                  effortlessly.
                </p>
              </li>
              <li>
                Angular / ReactJS
                <p>
                  These frameworks help me create modern, dynamic front-end experiences that keep
                  users engaged.
                </p>
              </li>
              <li>
                PostgreSQL / MongoDB
                <p>
                  Whether I’m dealing with unstructured data or large relational databases, I choose
                  the right storage strategy.
                </p>
              </li>
              <li>
                Flutter / React Native
                <p>
                  For mobile apps, these tools let me build cross-platform solutions that feel right
                  on both iOS and Android.
                </p>
              </li>
            </ul>
          </div>
          <div className="resume">
            <h3>Past&nbsp;Experience</h3>
            <p>More information about my education, achievements, and experience.</p>
            <a href="/resume.pdf">My Resume</a>
          </div>
        </div>
      </StyledAboutContent>
    </section>
  );
};

export default About;
