import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectWrapper = styled.div`
  &.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;

    @media (max-width: 834px) {
      padding: 0;
    }
  }
`;

const StyledProject = styled.div`
  &.project {
    position: relative;

    h3 {
      margin: 32px 0;
      font-size: var(--fz-exl);
      color: var(--slate);
    }

    &:hover {
      background-color: rgba(87, 76, 242, 0.4);
      transition: background-color 400ms;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -10;
      top: 0;
      left: 0;
      outline: 1px solid rgba(87, 76, 242, 0.4);
      outline-offset: -1px;
      background: linear-gradient(
          to bottom right,
          transparent calc(50% - 1px),
          rgba(87, 76, 242, 0.4) calc(50% - 1px),
          rgba(87, 76, 242, 0.4) 50%,
          transparent 50%
        ),
        linear-gradient(
          to bottom left,
          transparent calc(50% - 1px),
          rgba(87, 76, 242, 0.4) calc(50% - 1px),
          rgba(87, 76, 242, 0.4) 50%,
          transparent 50%
        );
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -5;
    }

    a {
      .project-img {
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          display: block;
        }
      }

      .project-desc {
        padding: 40px 40px 80px 80px;
        box-sizing: border-box;

        p {
          color: var(--light-blue);
        }

        .project-number {
          color: var(--light-blue);
          transition: color 200ms;
          font-weight: 500;
        }

        .project-arrow {
          position: relative;
          display: inline-block;
          background-color: #18033c;
          height: 1px;
          width: 40px;
          top: -5px;
          margin: 0 20px;
          transition: width 400ms;

          &:before {
            top: 0;
            transform: rotate(0);
            opacity: 0;
            content: '';
            position: absolute;
            background: #18033c;
            height: 1px;
            width: 10px;
            right: -2px;
            margin-right: 0;
            backface-visibility: hidden;
            transition: transform 400ms, top 400ms, opacity 200ms;
          }

          &:after {
            top: 0;
            transform: rotate(0);
            opacity: 0;
            content: '';
            position: absolute;
            background: #18033c;
            height: 1px;
            width: 10px;
            right: -2px;
            margin-right: 0;
            backface-visibility: hidden;
            transition: transform 400ms, top 400ms, opacity 200ms;
          }
        }

        .project-view {
          position: absolute;
          transform: translateX(-10px);
          opacity: 0;
          transition: transform 400ms, opacity 200ms, color 400ms;
          color: var(--light-blue);
          font-weight: 500;
        }

        .project-client {
          position: absolute;
          transform: translateX(0);
          opacity: 1;
          transition: transform 400ms, opacity 200ms, color 400ms;
          color: var(--light-blue);
          font-weight: 500;
        }
      }

      &:hover {
        color: #f3f2f4;

        .project-arrow {
          width: 50px;

          &:before {
            top: -3px;
            transform: rotate(30deg);
            margin-left: 5px;
            opacity: 1;
          }

          &:after {
            top: 3px;
            transform: rotate(-30deg);
            margin-left: 5px;
            opacity: 1;
          }
        }

        .project-number {
          color: #f3f2f4;
        }

        .project-view {
          transform: translateX(10px);
          opacity: 1;
          color: #f3f2f4;
        }

        .project-client {
          transform: translateX(30px);
          opacity: 0;
          color: #f3f2f4;
        }
      }
    }

    p {
      color: #f3f2f4;
    }

    &.project-1,
    &.project-5 {
      width: 55%;
      animation: anim-text 1200ms cubic-bezier(0, 0.45, 0.15, 1);
      margin-bottom: 130px;

      .intro-title {
        position: relative;

        .badge {
          width: 120px;
          height: 120px;
          background-color: #eea1be;
          border-radius: 200px;
          color: #f3f2f4;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          top: 0;
          right: 0;
          margin: -58px -146px 0 0;

          .text-default {
            font-size: 22px;
            position: absolute;
            transition: opacity 200ms, transform 400ms;
          }

          .text-hover {
            font-size: 16px;
            opacity: 0;
            transform: scale(0);
            transition: opacity 200ms, transform 200ms;
          }

          &:hover {
            cursor: crosshair;

            .text-hover {
              opacity: 1;
              transform: scale(1);
            }

            .text-default {
              opacity: 0;
              transform: scale(0);
            }
          }
        }
      }

      .project-img {
        background-color: #edf2f8;
      }
    }

    &.project-2 {
      align-self: flex-end;
      width: 40%;
      animation: anim-text 1250ms cubic-bezier(0, 0.45, 0.15, 1);

      .project-img {
        background-color: #edf2f8;
      }
    }

    &.project-3 {
      width: 40%;
      margin: 80px 0 180px 0;
      animation: anim-text 1300ms cubic-bezier(0, 0.45, 0.15, 1);

      .project-img {
        background-color: #e4809c;
      }
    }

    &.project-4 {
      margin-top: 260px;
      width: 55%;
      animation: anim-text 1350ms cubic-bezier(0, 0.45, 0.15, 1);

      .project-img {
        background-color: #f27f56;
      }
    }

    @media (max-width: 1080px) {
      &.project-1,
      &.project-5 {
        width: 100%;
        margin: 0;
        padding: 0;

        .intro-title .badge {
          width: 80px;
          height: 80px;
          margin: -36px -100px 0 0;

          .text-default {
            font-size: 18px;
          }

          .text-hover {
            font-size: 14px;
          }
        }
      }

      &.project-2 {
        width: 100%;
        margin: 60px 0 0 0;
        padding: 0;
        align-self: auto;

        .project-img {
          height: auto;
        }
      }

      &.project-3,
      &.project-4 {
        width: 100%;
        margin: 60px 0 0 0;
        padding: 0;
      }

      .project-desc {
        padding: 20px 20px 40px 40px !important;
      }
    }

    &.project-5 {
      margin: 80px 0 180px 0;
    }
  }
`;

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              subtitle
              external
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Things I’ve Built
      </h2>

      <StyledProjectWrapper className="projects">
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, subtitle, cover } = frontmatter;
            const image = getImage(cover);

            return (
              <StyledProject
                className={'project' + ` project-${i + 1}`}
                key={i}
                ref={el => (revealProjects.current[i] = el)}>
                <a href={external}>
                  <div className="wrapper">
                    <GatsbyImage image={image} alt={title} className="img" />
                  </div>
                  <div className="project-desc">
                    <span className="project-number">{`0${i + 1}`}</span>
                    <span className="project-arrow"></span>
                    <span className="project-hover">
                      <span className="project-client">{title}</span>
                      <span className="project-view">View project</span>
                    </span>
                    <h3>{subtitle}</h3>
                    <p dangerouslySetInnerHTML={{ __html: html }}></p>
                  </div>
                </a>
              </StyledProject>
            );
          })}
      </StyledProjectWrapper>
    </section>
  );
};

export default Featured;
