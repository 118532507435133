import React from 'react';

const IconTelegram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round">
    <title>Telegram</title>
    <path d="M9.5,22c-0.053,0-0.106-0.008-0.158-0.026C9.138,21.906,9,21.715,9,21.5v-7c0-0.143,0.062-0.279,0.168-0.374l5.248-4.666l-7.664,4.471c-0.146,0.084-0.325,0.09-0.476,0.015l-6-3c-0.177-0.088-0.285-0.272-0.275-0.47c0.009-0.198,0.133-0.371,0.316-0.443l23-9c0.171-0.065,0.363-0.035,0.504,0.083c0.14,0.118,0.205,0.302,0.169,0.481l-4,20c-0.031,0.158-0.137,0.291-0.283,0.357c-0.145,0.066-0.316,0.058-0.455-0.021l-6.617-3.781L9.9,21.8C9.804,21.928,9.654,22,9.5,22z M12.5,17c0.085,0,0.17,0.021,0.248,0.066l6.397,3.656L22.83,2.299L1.729,10.556l4.752,2.376l11.767-6.864c0.223-0.127,0.506-0.069,0.657,0.139c0.15,0.208,0.119,0.497-0.073,0.667L10,14.725V20l2.1-2.8C12.197,17.07,12.348,17,12.5,17z" />
  </svg>
);

export default IconTelegram;
