import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@components/icons';
import { socialMedia, email } from '@config';

const StyledFooter = styled.footer`
  ${({ theme }) => theme.mixins.flexCenter};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px 15px 100px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding: 0 50px 15px 50px;
  }

  @media (max-width: 480px) {
    padding: 0 25px 15px 25px;
  }
`;

const StyledSocialLinks = styled.div`
  border-bottom: 1px solid #574cf2;
  border-top: 1px solid #574cf2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 90px;
  width: 40%;
  ${({ theme }) => theme.mixins.flexBetween};

  .divider {
    height: 40px;
    width: 1px;
    background-color: #574cf2;
    flex-shrink: 0;
  }

  @media (min-width: 320px) and (max-width: 834px) {
    width: 100%;
    height: 120px;
    font-size: var(--fz-lg);
  }

  @media (min-width: 834px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledIconLink = styled.a`
  display: flex;
  align-items: flex-start;
  margin: 0 20px;
  flex-shrink: 0;

  svg {
    margin-right: 14px;
    width: 20px;
    height: 20px;
  }

  @media (min-width: 320px) and (max-width: 834px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin: 0 0 20px 0;
    }

    margin: 0;
  }
`;

const StyledComment = styled.div`
  border-bottom: 1px solid #574cf2;
  border-top: 1px solid #574cf2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 90px;
  width: 40%;

  @media (max-width: 1200px) {
    display: none;
  }

  p {
    margin: 0;
  }

  .divider {
    height: 40px;
    width: 1px;
    background-color: #574cf2;
    flex-shrink: 0;
  }
`;

const StyledCredit = styled.div`
  width: 100%;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 10px 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 15px 0;

    svg {
      width: 50px;
    }
  }
`;

const Footer = ({ isHome }) => (
  <>
    {isHome && (
      <StyledFooter>
        <StyledSocialLinks>
          {socialMedia &&
            socialMedia.map((el, i) => (
              <React.Fragment key={i}>
                <StyledIconLink
                  href={el.url}
                  target={'_blank'}
                  aria-label={el.name}
                  rel="noreferrer"
                >
                  <Icon name={el.name} />
                  {el.name}
                </StyledIconLink>
                {i + 1 !== socialMedia.length && <div className="divider"></div>}
              </React.Fragment>
            ))}
        </StyledSocialLinks>
        <StyledIcon>
          <Icon name={'Logo'} className="icon" />
        </StyledIcon>
        <StyledComment>
          <p>Email</p>
          <div className="divider"></div>
          <a href={`mailto:${email}`}>
            <div>{email}</div>
          </a>
        </StyledComment>
        <StyledCredit tabindex="-1">
          <a href={`mailto:${email}`}>
            <div>Eduard Yudinkov</div>
          </a>
        </StyledCredit>
      </StyledFooter>
    )}
  </>
);

Footer.propTypes = {
  isHome: PropTypes.bool,
};

export default Footer;
